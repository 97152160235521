import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PERSONNEL_BULK_IMPORT_URL,
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRY_URL,
  GET_ALL_PERSONNEL_URL,
  GET_PERSONNEL,
  GET_LOCATIONS,
  MERGE_PERSONNEL,
  CREATE_PERSONNEL_URL,
  EDIT_PERSONNEL_URL,
  GET_MANAGERS,
  GET_DEPARTMENTS,
  NOTIFY_USER_EMAIL,
  GET_COUNTIES,
  GET_REGIONS_URL,
  GET_STATES_BY_REGION_URL,
  GET_STATES_URL,
  GET_CURRENT_USER,
  GET_PORTERS,
  GET_SALES_PERSONNELS,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import {
  PersonnelPayload,
  GetStatesPayload,
  GetViewPersonnelPayload,
  MergePersonnelPayload,
  PersonnelBulkImportPayload,
  GetDepartmentPayload,
  NotifyUserEmailPayload,
  GetCountiesPayload,
} from "types/security";

import { GetFilteredOrSearchedPersonnelsPayload } from "types";
import {
  API_RESPONSE_TYPE,
  ERROR_MESSAGES,
  MULTIPART_HEADER,
  STATUS_CODE,
} from "appConstants";
import AppError from "../../../utils/AppError";

export const getAllPersonnels = createAsyncThunk(
  GET_ALL_PERSONNEL_URL,
  async (payload: GetFilteredOrSearchedPersonnelsPayload) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await getRequest<any>(GET_ALL_PERSONNEL_URL, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllPersonnels,
        error.response.data?.message
      );
    }
  }
);

export const getPersonnel = createAsyncThunk(
  GET_PERSONNEL,
  async (payload: GetViewPersonnelPayload) => {
    try {
      const response = await getRequest(GET_PERSONNEL, {
        params: payload,
        metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const getLocations = createAsyncThunk(GET_LOCATIONS, async () => {
  try {
    const response = await getRequest(GET_LOCATIONS);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getLocations,
      error.response.data?.message
    );
  }
});

export const getPorters = createAsyncThunk(GET_PORTERS, async () => {
  try {
    const response = await getRequest(GET_PORTERS);

    return response?.data ? response?.data : [];
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getPorters, error.response.data?.message);
  }
});

export const mergePersonnels = createAsyncThunk(
  MERGE_PERSONNEL,
  async (payload: MergePersonnelPayload) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await postRequest<any>(MERGE_PERSONNEL, payload);
      if (response.status === STATUS_CODE.SUCCESS)
        return response.data.status.message;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.mergePersonnels,
        error.response.data?.message
      );
    }
  }
);

export const uploadPersonnelBulkImportFile = createAsyncThunk(
  PERSONNEL_BULK_IMPORT_URL,
  async (payload: PersonnelBulkImportPayload) => {
    try {
      const response = await postRequest(
        PERSONNEL_BULK_IMPORT_URL,
        payload.formData,
        MULTIPART_HEADER
      );
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.uploadPersonnelBulkImportFile,
        error.response.data?.message
      );
    }
  }
);

export const getCountries = createAsyncThunk(GET_COUNTRIES, async () => {
  try {
    const response = await getRequest(GET_COUNTRIES);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getCountries,
      error.response.data?.message
    );
  }
});

export const getAllStates = createAsyncThunk(GET_STATES_URL, async () => {
  try {
    const response = await getRequest(GET_STATES_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getStates, error.response.data?.message);
  }
});

export const getRegionsByCountry = createAsyncThunk(
  GET_REGIONS_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await getRequest(`${GET_REGIONS_URL}/${payload.id}`);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getRegions,
        error.response.data?.message
      );
    }
  }
);

export const getStatesByCountry = createAsyncThunk(
  GET_STATES_BY_COUNTRY_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await getRequest(
        `${GET_STATES_BY_COUNTRY_URL}/${payload.id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getStates,
        error.response.data?.message
      );
    }
  }
);

export const getStatesByRegion = createAsyncThunk(
  GET_STATES_BY_REGION_URL,
  async (payload: GetStatesPayload) => {
    try {
      const response = await getRequest(
        `${GET_STATES_BY_REGION_URL}/${payload.id}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getStates,
        error.response.data?.message
      );
    }
  }
);

export const getCounties = createAsyncThunk(
  GET_COUNTIES,
  async (payload: GetCountiesPayload) => {
    try {
      const response = await getRequest(`${GET_COUNTIES}/${payload.id}`);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getCounties, error.response.data);
    }
  }
);

export const getManagers = createAsyncThunk(
  GET_MANAGERS,
  async (payload: GetDepartmentPayload) => {
    try {
      const response = await getRequest(`${GET_MANAGERS}/${payload}`);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getManagers,
        error.response.data?.message
      );
    }
  }
);

export const createPersonnel = createAsyncThunk(
  CREATE_PERSONNEL_URL,
  async (payload: PersonnelPayload) => {
    try {
      const response = await postRequest(CREATE_PERSONNEL_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const editPersonnel = createAsyncThunk(
  EDIT_PERSONNEL_URL,
  async (payload: PersonnelPayload) => {
    try {
      const response = await putRequest(EDIT_PERSONNEL_URL, payload);
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.editPersonnel,
        error.response.data?.message
      );
    }
  }
);

export const getDepartments = createAsyncThunk(GET_DEPARTMENTS, async () => {
  try {
    const response = await getRequest(GET_DEPARTMENTS);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getDepartments,
      error.response.data?.message
    );
  }
});

export const notifyUserEmail = createAsyncThunk(
  NOTIFY_USER_EMAIL,
  async (payload: NotifyUserEmailPayload) => {
    try {
      const response = await getRequest(NOTIFY_USER_EMAIL, {
        params: payload,
      });
      if (response.status === STATUS_CODE.SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.notifyUserEmail,
        error.response.data?.message
      );
    }
  }
);

export const getCurrentUser = createAsyncThunk(GET_CURRENT_USER, async () => {
  try {
    const response = await getRequest(GET_CURRENT_USER);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getCurrentUser,
      error.response.data?.message
    );
  }
});

export const getSalesPersonnels = createAsyncThunk(
  GET_SALES_PERSONNELS,
  async (payload: GetFilteredOrSearchedPersonnelsPayload) => {
    try {
      const response = await getRequest<any>(GET_SALES_PERSONNELS, {
        params: payload,
      });

      return response.data;
      return [
        {
          empId: "2235691",
          userId: "69211ae2-eecc-4acc-8c94-4d2e1ba7f185",
          userName: "testAutomationE2E Manager00",
        },
        {
          empId: "2235691",
          userId: "9a7dac23-9731-499d-8c98-8b7f4eb31253",
          userName: "testAutomationE2E Manager01",
        },
        {
          empId: "1235691",
          userId: "9f2f818f-75ee-484c-9327-59e9b5ca2360",
          userName: "testAutomationE2E User00",
        },
        {
          empId: "1235691",
          userId: "e0337dc9-b2ae-4776-ba94-c92702684956",
          userName: "testAutomationE2E User01",
        },
        {
          empId: "1235691",
          userId: "b5d44cdb-0e9a-4bf7-96e5-4cbdbfb872da",
          userName: "testAutomationE2E User10",
        },
        {
          empId: "1235691",
          userId: "503e9d03-b742-4d9f-a868-f13de1480a5d",
          userName: "testAutomationE2E User11",
        },
        {
          empId: "5496632",
          userId: "38395643-ef04-4805-9fbe-d468eb68c62c",
          userName: "testAutomationE2E User20",
        },
        {
          empId: "5496632",
          userId: "4e3bb4f5-ba39-4e6d-99ef-5b0077e78aad",
          userName: "testAutomationE2E EditUser01",
        },
      ];
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllPersonnels,
        error.response.data?.message
      );
    }
  }
);
