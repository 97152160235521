import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ASSOCIATE_COBUYERS,
  CREATE_BUYER,
  GET_BUYERS,
  GET_COBUYERS,
  TAGS_URL,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import { GetAllPayload, GetCoBuyersPayload } from "types";

export const getBuyers = createAsyncThunk(
  GET_BUYERS,
  async (payload: GetAllPayload) => {
    try {
      const response = await getRequest<any>(GET_BUYERS, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getBuyers,
        error.response.data?.message
      );
    }
  }
);

export const createBuyer = createAsyncThunk(
  CREATE_BUYER,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(CREATE_BUYER, payload);

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createBuyer,
        error.response.data?.message
      );
    }
  }
);

// INFO: AssociationId means if buyer is associated with any other buyer
export const getCoBuyers = createAsyncThunk(
  GET_COBUYERS,
  async (payload: GetCoBuyersPayload) => {
    try {
      const response = await getRequest<any>(GET_COBUYERS, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getCoBuyers,
        error.response.data?.message
      );
    }
  }
);

export const associateCoBuyers = createAsyncThunk(
  ASSOCIATE_COBUYERS,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(ASSOCIATE_COBUYERS, payload);

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.associateCoBuyers,
        error.response.data?.message
      );
    }
  }
);

export const getTags = createAsyncThunk(TAGS_URL, async () => {
  try {
    const response = await getRequest<any>(TAGS_URL);
    return response.data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getTags, error.response.data?.message);
  }
});
