import { useEffect, useState } from "react";
import { TextField, Autocomplete, Chip, TextFieldProps } from "@mui/material";
import { pxToRem, textFieldStyle } from "appConstants";

type TagsInputPropType = {
  availableTags: string[];
  addedTags: string[] | string; // Can be a single string or an array of strings
  multiple?: boolean; // Allow multiple selection or not
  label?: string; // Added label prop with default value
  textFieldProps?: TextFieldProps;
  onChange: (tags: string[]) => void; // Callback for changes
};

const TagsInput = (props: TagsInputPropType) => {
  const {
    availableTags = [],
    addedTags,
    multiple = true, // Default to multiple selection
    label = "Tags", // Default value for label
    textFieldProps = {},
    onChange,
  } = props;

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { sx: textFieldSx, ...textFieldPropObj } = textFieldProps || {};

  // Effect to set selectedTags on initial load or when addedTags change
  useEffect(() => {
    if (addedTags) {
      setSelectedTags(Array.isArray(addedTags) ? addedTags : [addedTags]);
    }
  }, [addedTags]);

  const handleBlur = () => {
    onChange(selectedTags); // Ensure updated value is passed to onChange
  };

  const handleChange = (event: any, newValue: string | string[]) => {
    if (multiple) {
      // If multiple, make sure it's an array of strings
      setSelectedTags(Array.isArray(newValue) ? newValue : []);
    } else {
      // If single value, handle as a single string
      setSelectedTags([String(newValue || "")]);
    }
  };

  const handleInputChange = (event: any, value: string) => {
    // Update the first tag if only one tag is selected and being edited
    if (!multiple) {
      setSelectedTags([value]);
    }
  };

  return (
    <Autocomplete
      multiple={multiple}
      freeSolo
      options={availableTags}
      value={multiple ? selectedTags : selectedTags[0] || ""} // Ensure a string when single value
      onChange={handleChange} // Use custom handleChange
      onBlur={handleBlur}
      onInputChange={handleInputChange} // Handle input changes for editing directly
      renderTags={(value, getTagProps) =>
        multiple
          ? (value as string[]).map((option, index) => (
              <Chip
                variant="filled"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          : null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label} // Use label prop
          sx={{ ...textFieldStyle, ...textFieldSx }}
          InputProps={{
            ...params.InputProps,
            sx: {
              py: "0 !important",
              pl: `${pxToRem(60)} !important`,
            },
          }}
          {...textFieldPropObj}
        />
      )}
    />
  );
};

export default TagsInput;
