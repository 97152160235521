import { personnelSuccessMessages } from "appConstants/index";

export const personnelFulfilled = (state, action) => {
  state.isLoading = false;
  state.personnels = action.payload.records;
  state.totalRecords = action.payload.totalRecords;
};

export const personnelRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const portersFulfilled = (state, action) => {
  state.isLoading = false;
  state.porters = action.payload;
};

export const portersRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const viewPersonnelFulfilled = (state, action) => {
  state.isLoading = false;
  state.personnel = action.payload;
};

export const viewPersonnelrejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const currentUserFulfilled = (state, action) => {
  state.isLoading = false;
  state.currentUser = action.payload;
};

export const currentUserRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const locationsFulfilled = (state, action) => {
  state.isLoading = false;
  state.locations = action.payload;
};

export const locationrejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const mergePersonnelsFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = action.payload;
};

export const uploadPersonnelBulkImportFileFulfilled = (state, action) => {
  state.isLoading = false;
  state.isImportSuccess = action.payload;
  state.successMessage = personnelSuccessMessages.personnelBulkUpload;
};

export const uploadPersonnelBulkImportFileRejected = (state, action) => {
  const { name, message } = action.error;
  state.isImportSuccess = false;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const countriesFulfilled = (state, action) => {
  state.isLoading = false;
  state.countries = action.payload;
};

export const countriesRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const regionsFulfilled = (state, action) => {
  state.isLoading = false;
  state.regions = action.payload;
};

export const regionsRejected = (state, action) => {
  const { name, message } = action.error;
  state.regions = [];
  state.states = [];
  state.counties = [];
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const statesFulfilled = (state, action) => {
  state.isLoading = false;
  state.states = action.payload;
};

export const statesRejected = (state, action) => {
  const { name, message } = action.error;
  state.states = [];
  state.counties = [];
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const countiesFulfilled = (state, action) => {
  state.isLoading = false;
  state.counties = action.payload;
};

export const countiesRejected = (state, action) => {
  const { name, message } = action.error;
  state.counties = [];
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const createPersonnelFulfilled = (state, action) => {
  state.createEditIsLoading = false;
  state.createEditIsSuccess = true;
  state.successMessage = personnelSuccessMessages.personnelCreated;
};

export const createPersonnelRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.createEditIsLoading = false;
};

export const editPersonnelFulfilled = (state, action) => {
  state.createEditIsLoading = false;
  state.createEditIsSuccess = true;
  state.successMessage = personnelSuccessMessages.personnelUpdated;
};

export const editPersonnelRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.createEditIsLoading = false;
};

export const departmentFulfilled = (state, action) => {
  state.isLoading = false;
  state.departments = action.payload;
};

export const departmentRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const managersFulfilled = (state, action) => {
  state.isLoading = false;
  state.managers = action.payload;
};

export const managersRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const notifyUserEmailFulfilled = (state, action) => {
  state.isLoading = false;
  state.notifyEmailSuccess = true;
  state.successMessage = personnelSuccessMessages.notifyEmailSuccess;
};

export const notifyUserEmailRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const salesPersonnelsFulfilled = (state, action) => {
  state.isLoading = false;
  state.salesPersonnels = action.payload;
};

export const salesPersonnelsRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};
