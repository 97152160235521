import { CustomerState } from "./customer.model";

export const getBuyersFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.buyers = action.payload?.records;
  state.buyerTotalRecords = action.payload?.totalRecords;
};

export const getBuyersRejected = (state: CustomerState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const createBuyerFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.createdCustomer = action.payload;
};

export const createBuyerRejected = (state: CustomerState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getCoBuyersFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.cobuyers = action.payload?.records;
  state.coBuyerTotalRecords = action.payload?.totalRecords;
};

export const getCoBuyersRejected = (state: CustomerState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const associateCoBuyersFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
};

export const associateCoBuyersRejected = (state: CustomerState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getTagsFullfilled = (state: CustomerState, action) => {
  state.isLoading = false;
  state.tags = action.payload?.tags;
};

export const getTagsRejected = (state: CustomerState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};
