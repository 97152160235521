import { forwardRef } from "react";
import { Grid, Typography } from "@mui/material";
import { ActiveRecordChip, Button, ProfileMenu } from "components";
import {
  gutterSpace,
  linkButtonGrey50,
  pxToRem,
  LIST_VIEW_HEADER_TYPE,
} from "appConstants";
import { TodayTwoTone, AccountCircle } from "@mui/icons-material";
import SearchBar from "../SearchBar";
import useListViewHeader from "./useListViewHeader";
import ScanToUpdate from "containers/inventory/dispatch/ScanToUpdate";
import { SearchBarRef } from "types/finance";

interface ListViewHeaderPropType {
  pageHeading?: string;
  searchPlaceholder?: string;
  showFilterListIcon?: boolean;
  showSearchBar?: boolean;
  showProfileAvatar?: boolean;
  listViewHeaderType?: LIST_VIEW_HEADER_TYPE;
  createButton?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  rightColumn?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  searchParam?: string;
  sx?: object;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
  searchResultsList?: React.ReactNode;
  showCurrentDealership?: boolean;
  onBulkImportClick?: () => void;
  handleFilter?: () => void;
  searchHandler?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  setShowSearchResultsPopper?: (value: boolean) => void;
}

const Header = forwardRef<SearchBarRef, ListViewHeaderPropType>(
  (props: ListViewHeaderPropType, ref) => {
    const {
      pageHeading,
      searchPlaceholder,
      showFilterListIcon,
      showSearchBar,
      showProfileAvatar = false,
      listViewHeaderType,
      createButton,
      rightColumn,
      showCurrentDealership = false,
      searchParam,
      sx,
      rightButton,
      leftButton,
      searchResultsList,
      handleFilter,
      searchHandler,
      onBulkImportClick,
    } = props;

    const {
      showFilterListIcon: filterListIcon,
      showBulkImport,
      showCalendar,
      scanToUpdate,
      searchPlaceholder: searchPlaceholderText,
      showProfileAvatar: profileAvatar,
      showSearchBar: searchBar,
      isOpen,
      toggleModal,
      handleDispatchCalendar,
    } = useListViewHeader(listViewHeaderType);

    return (
      <Grid
        container
        alignItems="center"
        // spacing={gutterSpace}
        sx={{
          paddingTop: 0,
          marginBottom: "20px",
          alignItems: "center",
          ...sx,
        }}
      >
        {pageHeading && (
          <Grid item xs flex={1}>
            <Typography fontWeight={600} letterSpacing={pxToRem(0.15)}>
              {pageHeading}
            </Typography>
          </Grid>
        )}

        {/* create button group */}
        {(createButton ||
          showBulkImport ||
          showCalendar ||
          scanToUpdate ||
          showCurrentDealership) && (
          <Grid item xs={showCurrentDealership ? 2.5 : "auto"} pr={gutterSpace}>
            {createButton}
            {showBulkImport && (
              <Button
                startIcon={<AccountCircle />}
                variant="text"
                sx={linkButtonGrey50}
                onClick={onBulkImportClick}
              >
                Bulk Import
              </Button>
            )}
            {showCalendar && (
              <Button
                startIcon={<TodayTwoTone />}
                variant="text"
                sx={linkButtonGrey50}
                onClick={handleDispatchCalendar}
              >
                Calendar
              </Button>
            )}
            {scanToUpdate && <ScanToUpdate />}
            {showCurrentDealership && (
              <ActiveRecordChip
                text={"Fairfield #12"}
                subText={"(814) 992-5542"}
                bgColor="success.50"
                textColor="grey.A400"
                backgroundColorAnimation={false}
              />
            )}
          </Grid>
        )}
        {leftButton && (
          <Grid item xs="auto" display={"flex"} pl={gutterSpace}>
            {leftButton}
          </Grid>
        )}

        {/* search bar group */}
        {(rightColumn || (showSearchBar ?? searchBar)) && (
          <Grid item xs sx={{ margin: 0, padding: 0 }}>
            {rightColumn}
            {(showSearchBar ?? searchBar) && (
              <SearchBar
                showSearchIcon
                showFilterListIcon={showFilterListIcon ?? filterListIcon}
                handleFilter={handleFilter}
                placeholder={searchPlaceholder || searchPlaceholderText}
                searchHandler={searchHandler}
                searchParam={searchParam}
                ref={ref}
              >
                {searchResultsList}
              </SearchBar>
            )}
          </Grid>
        )}

        {/* user button options */}
        {rightButton && (
          <Grid item xs="auto" display={"flex"} pl={gutterSpace}>
            {rightButton}
          </Grid>
        )}

        {/* Profile icon */}
        {(showProfileAvatar ?? profileAvatar) && (
          <Grid item xs="auto" display={"flex"} pl={gutterSpace}>
            <ProfileMenu />
          </Grid>
        )}
      </Grid>
    );
  }
);

export default Header;
